<template>
  <div class='bind'>
    <el-row type="flex"
            :gutter="10"
            justify="center">
      <el-col :span="24">

        <el-tabs v-model="activeName"
                 :stretch="true">
          <el-tab-pane label="绑定"
                       name="bind">
            <el-form ref="loginForm"
                     :model="form"
                     :rules="loginRules"
                     label-width="80px">
              <el-form-item label="账号"
                            prop="username">
                <el-input v-model="form.username"></el-input>
              </el-form-item>
              <el-form-item label="密码"
                            prop="password">
                <el-input type="password"
                          v-model="form.password"></el-input>
              </el-form-item>

              <el-form-item label="验证码"
                            prop="verifyCode">
                <el-input v-model="form.verifyCode"
                          auto-complete="off"
                          placeholder="验证码"
                          style="width: 63%"></el-input>
                <div class="login-code">
                  <img :src="codeUrl"
                       @click="getCode" />
                </div>
              </el-form-item>

              <el-form-item>
                <el-button type="primary"
                           :loading="loading"
                           @click="onSubmit">绑 定</el-button>
                <el-button @click="reset">重 置</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>

          <el-tab-pane label="注 册"
                       name="register">
            <el-form ref="registerForm"
                     :model="registerForm"
                     :rules="registerRules"
                     label-width="80px">
              <el-form-item label="邮箱"
                            prop="memEmail">
                <el-input v-model="registerForm.memEmail"></el-input>
              </el-form-item>
              <el-form-item label="用户名"
                            prop="memName">
                <el-input v-model="registerForm.memName"></el-input>
              </el-form-item>
              <el-form-item label="密码"
                            prop="memPassword">
                <el-input type="password"
                          v-model="registerForm.memPassword"></el-input>
              </el-form-item>
              <el-form-item label="手机号"
                            prop="memPhone">
                <el-input v-model="registerForm.memPhone"></el-input>
              </el-form-item>

              <el-form-item label="验证码"
                            prop="verifyCode">
                <el-input v-model="registerForm.verifyCode">
                  <el-button slot="append"
                             @click="sendCaptchaByMobile">发送验证码</el-button>
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary"
                           :loading="loading"
                           @click="registerOnSubmit">注册并绑定</el-button>
                <el-button @click="registerReset">重 置</el-button>
              </el-form-item>
            </el-form>

          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCodeImg, registerVerifyCode, bind, registerBind } from '@/api/login'
import { checkPhone, checkEmail } from '@/utils/validate'

export default {
  name: 'BindAccount',
  props: {
    openid: {
      type: String,
      required: true,
      defalut: ''
    }
  },
  data () {
    return {
      activeName: 'bind',
      loading: false,
      form: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ],
        verifyCode: [
          { required: true, trigger: 'blur', message: '验证码必须' }
        ]
      },
      registerForm: {},
      registerRules: {
        memEmail: [
          { required: true, validator: checkEmail, trigger: 'blur' }
        ],
        memName: [
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        memPassword: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ],
        memPhone: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, trigger: 'blur', message: '验证码必须' }
        ]
      },
      codeUrl: '', // 验证码11
      mobileVerifyCode: '' // 发送手机验证码的验证
    }
  },
  created () {
    console.log(this.openid)
    this.getCode()
  },
  methods: {
    // 发送手机验证码
    sendCaptchaByMobile () {
      if (!this.registerForm.memPhone || this.registerForm.memPhone === '') {
        this.$message.warning('请输入手机号')
        return false
      }
      this.getCode()
      const _this = this
      this.$dialog({ title: '请输入验证码', width: '400px' }).content(h => {
        return [h('el-input', {
          style: {
            width: '63%'
          },
          props: {
            value: _this.mobileVerifyCode
          },
          on: {
            input: value => {
              _this.mobileVerifyCode = value
            }
          }
        }), h('span', { class: 'login-code' }, [h('img', { attrs: { src: _this.codeUrl }, on: { click: _this.getCode } })])]
      }).confirm(() => {
        if (this.mobileVerifyCode === '') {
          this.$message.warning('请输入验证码')
          return false
        }
        if (!this.registerForm.memPhone || this.registerForm.memPhone === '') {
          this.$message.warning('请输入手机号')
          return false
        }
        return registerVerifyCode({
          memPhone: this.registerForm.memPhone,
          verifyCode: this.mobileVerifyCode,
          verifyKey: this.registerForm.verifyKey
        }).then(res => {
          const { code, msg } = res
          if (code !== 0) {
            this.$message.error(msg || '验证码发送错误！')
            this.getCode()
            return false
          }
          this.$message.success(msg || '验证码发送成功！')
          return true
        }).catch(err => {
          console.log(err)
          this.getCode()
          return false
        })
      }).cancel(() => {
        this.mobileVerifyCode = ''
      })
    },
    // 获取图形验证
    getCode () {
      getCodeImg().then(res => {
        this.codeUrl = res.data.base64stringC
        this.form.verifyKey = res.data.idKeyC
        this.registerForm.verifyKey = res.data.idKeyC
      })
    },
    // 账号绑定
    onSubmit () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          bind({ ...this.form, openid: this.openid }).then(res => {
            const { code, data, msg } = res
            if (code === 0) {
              this.reset()
              this.$emit('success', data)
            } else {
              this.$message.error(msg || '绑定失败')
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    // 重置
    reset () {
      this.$refs.loginForm.resetFields()
    },
    // 注册并绑定
    registerOnSubmit () {
      // console.log(this.registerForm)
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          this.loading = true
          registerBind({ ...this.registerForm, openid: this.openid }).then(res => {
            const { code, data, msg } = res
            if (code === 0) {
              this.registerReset()
              this.$emit('success', data)
              return Promise.resolve()
            }
            this.$message.error(msg)
            return Promise.reject(msg)
          }).catch(() => {
            this.getCode()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    registerReset () {
      this.$refs.registerForm.resetFields()
    }
  }
}
</script>

<style>
</style>
