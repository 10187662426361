
class Ws {
  #socketUrl;
  #webSocket;
  #onopen;
  #onmessage;
  #onerror;
  #onclose;
  #flag = true
  #isClose = false;
  #timeOut = 5000;
  #timeObj = null;
  #serverTimeObj = null;
  #query = {}
  // eslint-disable-next-line space-before-function-paren
  constructor(socketUrl, onopen = null, onmessage = null, onerror = null, onclose = null) {
    this.#socketUrl = socketUrl
    if (typeof onopen === 'function') {
      this.#onopen = onopen
    }
    if (typeof onmessage === 'function') {
      this.#onmessage = onmessage
    }
    if (typeof onerror === 'function') {
      this.#onerror = onerror
    }
    if (typeof onclose === 'function') {
      this.#onclose = onclose
    }
  }

  get onopen () {
    return this.#onopen
  }

  set onopen (fn) {
    if (typeof fn === 'function') {
      this.#onopen = fn
    }
  }

  get onmessage () {
    return this.#onmessage
  }

  set onmessage (fn) {
    if (typeof fn === 'function') {
      this.#onmessage = fn
    }
  }

  get onerror () {
    return this.#onerror
  }

  set onerror (fn) {
    if (typeof fn === 'function') {
      this.#onerror = fn
    }
  }

  get onclose () {
    return this.#onclose
  }

  set onclose (fn) {
    if (typeof fn === 'function') {
      this.#onclose = fn
    }
  }

  setQuery (query) {
    this.#query = query
    return this
  }

  connect () {
    this.#isClose = false
    try {
      this.#webSocket = new WebSocket(this.getSocketUrl())
      this.init()
    } catch (e) {
      console.log('connect连接错误', e)
      this.reConnect()
    }

    return this.#webSocket
  }

  getSocketUrl () {
    const query = this.createQuery()
    if (query !== '') {
      return this.#socketUrl + '?' + query
    } else {
      return this.#socketUrl
    }
  }

  createQuery () {
    var arr = []
    if (Object.keys(this.#query).length > 0) {
      for (var prop in this.#query) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.#query.hasOwnProperty(prop)) {
          arr.push(prop + '=' + this.#query[prop])
        }
      }
    }
    return arr.length > 0 ? arr.join('&') : ''
  }

  closeConnet () {
    this.#isClose = true
    this.heartbeatClear()
    this.#webSocket && this.#webSocket.close()
  }

  heartbeat () {
    if (this.#isClose) {
      return false
    }
    this.heartbeatClear()
    this.#timeObj = setTimeout(() => {
      this.sendmsg({ cmd: 0, content: 'ping' })
      this.#serverTimeObj = setTimeout(() => {
        this.#webSocket.close()
        this.reConnect()
      }, this.#timeOut)
    }, this.#timeOut)
  }

  heartbeatClear () {
    this.#timeObj && clearTimeout(this.#timeObj)
    this.#serverTimeObj && clearTimeout(this.#serverTimeObj)
  }

  reConnect () {
    if (!this.#flag) {
      return false
    }
    this.#flag = false
    setTimeout(() => {
      this.connect()
      this.#flag = true
    }, this.#timeOut)
  }

  sendmsg (msg) {
    this.#webSocket.send(JSON.stringify(msg))
  }

  init () {
    this.#webSocket.onopen = () => {
      console.log('websocket连接成功!!')
      if (typeof this.#onopen === 'function') {
        // eslint-disable-next-line no-useless-call
        this.#onopen.call(this)
      }
      this.heartbeat()
    }
    this.#webSocket.onmessage = (evt) => {
      if (typeof this.#onmessage === 'function') {
        var message = {}
        var re = /^\{.+\}$/
        if (re.test(evt.data)) {
          message = JSON.parse(evt.data)
          const { cmd, media, content } = message
          if (cmd !== 0) {
            const c = media === 1 ? JSON.parse(content) : content
            // eslint-disable-next-line no-useless-call
            this.#onmessage.call(this, cmd, media, c)
          }
        }
        // console.log(evt)
      }
      this.heartbeat()
    }
    this.#webSocket.onerror = () => {
      console.log('websocket连接报错')
      if (typeof this.#onerror === 'function') {
        // eslint-disable-next-line no-useless-call
        this.#onerror.call(this)
      }
    }
    this.#webSocket.onclose = () => {
      console.log('websocket连接关闭')
      if (typeof this.#onclose === 'function') {
        // eslint-disable-next-line no-useless-call
        this.#onclose.call(this)
      }
    }
  }
}

export default Ws
