<template>
  <div class='login'>
    <el-row type="flex"
            :gutter="10"
            justify="center">
      <el-col :span="6">

        <el-tabs v-model="activeName"
                 :stretch="true"
                 style="min-width:390px;"
                 @tab-click="handleClick">
          <el-tab-pane label="登 录"
                       name="login">
            <el-form ref="loginForm"
                     :model="form"
                     :rules="loginRules"
                     label-width="80px">
              <el-form-item label="账号"
                            prop="username">
                <el-input v-model="form.username"></el-input>
              </el-form-item>
              <el-form-item label="密码"
                            prop="password">
                <el-input type="password"
                          v-model="form.password"></el-input>
              </el-form-item>

              <el-form-item label="验证码"
                            prop="code">
                <el-input v-model="form.code"
                          auto-complete="off"
                          placeholder="验证码"
                          style="width: 63%"></el-input>
                <div class="login-code">
                  <img :src="codeUrl"
                       @click="getCode" />
                </div>
              </el-form-item>
              <el-row :gutter="10"
                      type="flex"
                      justify="space-between"
                      style="margin-bottom: 22px;">
                <el-col :offset="5"
                        :span="6">
                  <el-checkbox v-model="form.rememberMe">记住账号</el-checkbox>
                </el-col>
                <el-col :span="6">
                  <span style="font-size:14px;height:21px;line-height:21px;color:#606266;font-weight: 500;cursor: pointer;"
                        @click="$router.push({path:'/forgetPassword'})"> 忘记密码？</span>
                </el-col>
              </el-row>
              <el-form-item>
                <el-button type="primary"
                           :loading="loading"
                           @click="onSubmit">登 录</el-button>
                <el-button @click="reset">重 置</el-button>
              </el-form-item>
              <!-- 微信登录 -->
              <el-row :gutter="10">
                <el-col :offset="5"
                        :span="4">
                  <el-button circle
                             @click="wxLogin">
                    <svg class="icon"
                         aria-hidden="true">
                      <use xlink:href="#icon-weixin"></use>
                    </svg>
                  </el-button>
                </el-col>
                <!-- QQ登录 -->
                <!-- <el-col :span="4">
                  <el-button circle
                             @click="qqLogin">
                    <svg class="icon"
                         aria-hidden="true">
                      <use xlink:href="#icon-QQ"></use>
                    </svg>
                  </el-button>
                </el-col> -->
              </el-row>
            </el-form>
          </el-tab-pane>

          <el-tab-pane label="注 册"
                       name="register">
            <el-form ref="registerForm"
                     :model="registerForm"
                     :rules="registerRules"
                     label-width="80px">
              <el-form-item label="邮箱"
                            prop="memEmail">
                <el-input v-model="registerForm.memEmail"></el-input>
              </el-form-item>
              <el-form-item label="用户名"
                            prop="memName">
                <el-input v-model="registerForm.memName"></el-input>
              </el-form-item>
              <el-form-item label="密码"
                            prop="memPassword">
                <el-input type="password"
                          v-model="registerForm.memPassword"></el-input>
              </el-form-item>
              <el-form-item label="手机号"
                            prop="memPhone">
                <el-input v-model="registerForm.memPhone"></el-input>
              </el-form-item>

              <el-form-item label="验证码"
                            prop="verifyCode">
                <el-input v-model="registerForm.verifyCode">
                  <el-button slot="append"
                             @click="sendCaptchaByMobile">发送验证码</el-button>
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary"
                           :loading="loading"
                           @click="registerOnSubmit">注 册</el-button>
                <el-button @click="registerReset">重 置</el-button>
              </el-form-item>
            </el-form>

          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import Cookies from 'js-cookie'
// eslint-disable-next-line no-unused-vars
import { register, getCodeImg, registerVerifyCode, createQrcode } from '@/api/login'
import { checkPhone, checkEmail } from '@/utils/validate'
import bind from '@/views/components/BindAccount'
import Ws from '@/utils/ws'
// eslint-disable-next-line no-unused-vars
const ws = new Ws(process.env.VUE_APP_WS_URL + '/codeStoreFront/scanLogin')
export default {
  name: 'login',
  data () {
    return {
      activeName: 'login',
      loading: false,
      form: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ],
        code: [
          { required: true, trigger: 'blur', message: '验证码必须' }
        ]
      },
      registerForm: {},
      registerRules: {
        memEmail: [
          { required: true, validator: checkEmail, trigger: 'blur' }
        ],
        memName: [
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        memPassword: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ],
        memPhone: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, trigger: 'blur', message: '验证码必须' }
        ]
      },
      codeUrl: '', // 验证码
      redirect: undefined,
      mobileVerifyCode: '', // 发送手机验证码的验证
      wxScanDialog: undefined // 微信扫码登录窗口
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created () {
    this.getCode()
    this.getCookie()
  },
  methods: {
    // qq登录
    qqLogin () {

    },
    // 微信二维码登录
    wxLogin () {
      createQrcode().then(res => {
        const { code, data, msg } = res
        if (code === 0) {
          const { imgUrl, qrcode } = data || {}
          ws.onmessage = (cmd, type, msg) => {
            this.onMessage(cmd, type, msg)
          }
          // 建立连接
          ws.setQuery({ ticket: qrcode.ticket }).connect()
          // 微信二维码
          this.wxScanDialog = this.$dialog({ title: '微信登录', width: '470px' }, false).content(h => {
            return [h('el-image', { style: { width: '430px', height: '430px' }, attrs: { src: imgUrl } })]
          }).cancel(() => {
            ws.closeConnet()
          })
          return true
        }
        this.$message.error(msg || '获取二维码失败')
        return false
      })
    },
    onMessage (cmd, type, msg) {
      console.log(cmd, type, msg)
      // 已有绑定账号直接登录
      if (cmd === 3) {
        ws.closeConnet()
        this.wxScanDialog && this.wxScanDialog.close()
        const { token, userInfo } = msg
        this.$store.dispatch('AutoLogin', { token, userInfo })
        this.$router.push({ path: this.redirect || '/personalCenter/personalCenter' })
        return false
      }
      // 账号绑定或注册
      if (cmd === 4) {
        ws.closeConnet()
        this.wxScanDialog && this.wxScanDialog.close()
        const bindDialog = this.$dialog({ title: '账号绑定', width: '500px', 'close-on-click-modal': false }, false).content(h => h(bind, {
          props: { openid: msg },
          on: {
            success: ({ token, userInfo }) => {
              this.$store.dispatch('AutoLogin', { token, userInfo })
              this.$router.push({ path: '/personalCenter/personalCenter' })
              bindDialog.close()
            }
          }
        }))
        return false
      }
    },
    // 发送手机验证码
    sendCaptchaByMobile () {
      if (!this.registerForm.memPhone || this.registerForm.memPhone === '') {
        this.$message.warning('请输入手机号')
        return false
      }
      this.getCode()
      const _this = this
      this.$dialog({ title: '请输入验证码', width: '400px' }).content(h => {
        return [h('el-input', {
          style: {
            width: '63%'
          },
          props: {
            value: _this.mobileVerifyCode
          },
          on: {
            input: value => {
              _this.mobileVerifyCode = value
            }
          }
        }), h('span', { class: 'login-code' }, [h('img', { attrs: { src: _this.codeUrl }, on: { click: _this.getCode } })])]
      }).confirm(() => {
        if (this.mobileVerifyCode === '') {
          this.$message.warning('请输入验证码')
          return false
        }
        if (!this.registerForm.memPhone || this.registerForm.memPhone === '') {
          this.$message.warning('请输入手机号')
          return false
        }
        return registerVerifyCode({
          memPhone: this.registerForm.memPhone,
          verifyCode: this.mobileVerifyCode,
          verifyKey: this.registerForm.verifyKey
        }).then(res => {
          const { code, msg } = res
          if (code !== 0) {
            this.$message.error(msg || '验证码发送错误！')
            this.getCode()
            return false
          }
          this.$message.success(msg || '验证码发送成功！')
          return true
        }).catch(err => {
          console.log(err)
          this.getCode()
          return false
        })
      }).cancel(() => {
        this.mobileVerifyCode = ''
      })
    },
    getCode () {
      getCodeImg().then(res => {
        this.codeUrl = res.data.base64stringC
        this.form.uuid = res.data.idKeyC
        this.registerForm.verifyKey = res.data.idKeyC
      })
    },
    getCookie () {
      const username = Cookies.get('username')
      const rememberMe = Cookies.get('rememberMe')
      this.$set(this.form, 'username', username || '')
      this.$set(this.form, 'rememberMe', rememberMe === undefined ? false : Boolean(rememberMe))
    },
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    // 登录
    onSubmit () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.form.rememberMe) {
            Cookies.set('username', this.form.username, { expires: 30 })
            Cookies.set('rememberMe', this.form.rememberMe, { expires: 30 })
          } else {
            Cookies.remove('username')
            Cookies.remove('rememberMe')
          }
          this.$store.dispatch('Login', this.form).then(() => {
            this.$router.push({ path: this.redirect || '/personalCenter/personalCenter' })
          }).catch(err => {
            this.$message.error(err)
            this.loading = false
            this.getCode()
          })
        }
      })
    },
    // 重置
    reset () {
      this.$refs.loginForm.resetFields()
    },
    // 注册
    registerOnSubmit () {
      // console.log(this.registerForm)
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          this.loading = true
          register(this.registerForm).then(res => {
            const { code, msg } = res
            if (code === 0) {
              this.$message.success('注册成功')
              return Promise.resolve()
            }
            this.$message.error(msg)
            return Promise.reject(msg)
          }).then(() => {
            this.activeName = 'login'
            this.$set(this.form, 'username', this.registerForm.memName)
            this.$set(this.form, 'password', this.registerForm.memPassword)
            this.registerReset()
            this.getCode()
          }).catch(res => {
            this.getCode()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    registerReset () {
      this.$refs.registerForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  margin-top: 100px;
}
</style>
<style lang="less">
.login-code {
  width: 36%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
    width: 120px;
  }
}
</style>
